import { checkAgentHubStatus } from 'actions/AgentHubActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'

const EmptyList: App.AgentHubEmailCheckStatus = {
  fetching: false,
  error: undefined,
  status: '',
  agentId: '',
}

function useAgentHubAgentStatus(agentEmail: string) {
  const dispatch = useAppDispatch()

  const agentStatus = useAppSelector(state => state.agentHub.agentStatusByEmail[agentEmail]) ?? EmptyList

  useEffect(() => {
    dispatch(checkAgentHubStatus(agentEmail))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentEmail])

  return agentStatus
}

export default useAgentHubAgentStatus
