import React from 'react'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import config from 'constants/config'
import { useIsMobileScreen } from 'lib/web/deviceUtils'
import styled from 'styled-components'
import { rem } from 'polished'
import { getSVGImageUrl } from 'lib/image/imageUtils'

const Slide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: ${rem(150)};
  height: ${rem(74)};
  background-color: #fff;
`

function PartnerCarousel() {
  const isMobileScreen = useIsMobileScreen()
  const itemsToShow = isMobileScreen ? 3 : 7

  return <CardCarousel pageSize={itemsToShow} gap={12}>
    {config.logoImages?.map((logo) => (
      <Slide key={logo.name} >
        <img
          src={getSVGImageUrl(logo.id)}
          alt={`${logo.name} logo`}
          width={logo.width}
        />
      </Slide>
    ))}
  </CardCarousel>
}

export default PartnerCarousel
